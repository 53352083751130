import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { Film, getFilm } from '@app/api/resources/Film';

import { setFilm } from '@app/actions/FilmActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const getFilmFromStore = (
  filmIdOrSlug: number | string,
  films: {
    [key: string]: Film;
  },
  slugToIdLookup: {
    [key: string]: number;
  },
) => {
  let initialFilmInStore = null;
  if (typeof filmIdOrSlug === 'string') {
    const mappedFilmId = slugToIdLookup[filmIdOrSlug];
    initialFilmInStore = films?.[mappedFilmId];
  } else if (typeof filmIdOrSlug === 'number') {
    initialFilmInStore = films?.[filmIdOrSlug];
  }
  return initialFilmInStore;
};

export const usePopulateFilmForFilmId = (
  filmIdOrSlug: number | string,
): [Film, boolean, boolean] => {
  const { httpContext, films, slugToIdLookup } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      films: state.film.films,
      slugToIdLookup: state.film.slugToIdLookup,
    }),
    shallowEqual,
  );

  const initialFilmInStore = getFilmFromStore(
    filmIdOrSlug,
    films,
    slugToIdLookup,
  );

  const [filmForId, setFilmForId] = useState(initialFilmInStore);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(!initialFilmInStore);
  const dispatch = useDispatch();

  const doPopulateFilmForFilmId = async () => {
    setIsLoading(true);
    const filmInStore = getFilmFromStore(filmIdOrSlug, films, slugToIdLookup);

    if (!filmInStore) {
      try {
        const filmForFilmId = await getFilm(httpContext, filmIdOrSlug);
        const film = filmForFilmId.data;
        setFilmForId(film);
        dispatch(setFilm(film));
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setFilmForId(filmInStore);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (filmIdOrSlug) {
      doPopulateFilmForFilmId();
    } else {
      setFilmForId(null);
    }
  }, [filmIdOrSlug]);

  const filmToReturn =
    Number(filmIdOrSlug) === filmForId?.id || filmIdOrSlug === filmForId?.slug
      ? filmForId
      : null;

  return [filmToReturn, isLoading, isError];
};
