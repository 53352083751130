import { Film } from '@app/api/resources/Film';

import * as actionTypes from '@app/actionTypes';

export const setFilm = (film: Film) => ({
  type: actionTypes.SET_FILM,
  payload: {
    film,
  },
});

export const setFilms = (films: Film[]) => ({
  type: actionTypes.SET_FILMS,
  payload: {
    films,
  },
});
