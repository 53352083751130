import useTranslation from 'next-translate/useTranslation';

import MubiButton from '@app/components/buttons/MubiButton/MubiButton';
import OnboardingSignupCtaButtonContainer from '@app/components/onboarding/OnboardingSignupCtaButtonContainer';
import PitchBannerBase from '@app/components/pitch-banner/PitchBannerBase';

type PitchBannerWatchableProps = {
  onButtonClick: () => void;
  trialsEnabled: boolean;
};

const PitchBannerWatchable = ({
  onButtonClick,
  trialsEnabled,
}: PitchBannerWatchableProps) => {
  const { t } = useTranslation('common');

  return (
    <PitchBannerBase
      headerText={t('common:common.pitch_banner.want_to_watch_now')}
      onButtonClick={onButtonClick}
    >
      <OnboardingSignupCtaButtonContainer>
        {({ showModal, context, ctaButtonText }) => (
          <MubiButton
            clickAction={() => {
              onButtonClick();
              showModal(context);
            }}
          >
            {context.flow === 'promoNew' || trialsEnabled
              ? ctaButtonText
              : t('common:common.pitch_banner.get_started')}
          </MubiButton>
        )}
      </OnboardingSignupCtaButtonContainer>
    </PitchBannerBase>
  );
};

export default PitchBannerWatchable;
