import { useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import throttle from 'lodash/throttle';

import { Film } from '@app/api/resources/Film';
import {
  addToWatchList,
  getWatchListItemForFilmId,
  removeFromWatchList,
} from '@app/api/resources/WatchList';

import {
  addFilmToWatchList,
  removeFilmFromWatchList,
} from '@app/actions/UserActions';

import useIsFilmInWatchList from '@app/hooks/helpers/useIsFilmInWatchList';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

type ChildRenderProps = {
  isFilmInWatchList: boolean;
  doClickOnWatchListButton: () => void;
  tooltipMessage: string;
};
type WatchListButtonContainerProps = {
  children: ({
    isFilmInWatchList,
    doClickOnWatchListButton,
    tooltipMessage,
  }: ChildRenderProps) => JSX.Element;
  film: Film;
  snowplowElement: string;
};

const WatchListButtonContainer = ({
  children,
  film,
  snowplowElement,
}: WatchListButtonContainerProps) => {
  const { t } = useTranslation('film_actions');
  const { httpContext, isAuthenticated, watchList } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      isAuthenticated: state.user.isAuthenticated,
      watchList: state.user.watchList,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [tooltipMessageTimeoutId, setTooltipMessageTimeoutId] = useState(null);
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const setTemporaryTooltipMessage = temporaryTooltipMessage => {
    const numSecondsToShowTooltip = 2;
    if (tooltipMessageTimeoutId) {
      clearTimeout(tooltipMessageTimeoutId);
    }
    setTooltipMessage(temporaryTooltipMessage);
    const currTimeoutId = setTimeout(() => {
      setTooltipMessage('');
      setTooltipMessageTimeoutId(false);
    }, numSecondsToShowTooltip * 1000);
    setTooltipMessageTimeoutId(currTimeoutId);
  };

  const doClickOnWatchListButton = throttle(async () => {
    const wishListItem = getWatchListItemForFilmId(film.id, watchList);
    if (wishListItem.wishId) {
      // to avoid a 404 from the default id
      if (wishListItem.wishId !== 1) {
        removeFromWatchList(httpContext, wishListItem.wishId);
      }
      dispatch(removeFilmFromWatchList(film.id));
      setTemporaryTooltipMessage(
        t('film_actions:film_actions.watchlist.tooltip_removed'),
      );
      trackSnowplowClickEvent({
        data: {
          clickType: 'film_action_remove_watchlist',
          element: snowplowElement,
        },
        film,
      });
    } else {
      dispatch(
        addFilmToWatchList({
          // temp id 1 here so that the button changes state before the request completes
          id: 1,
          film: {
            id: film.id,
          },
          isLoading: false,
        }),
      );
      setTemporaryTooltipMessage(
        t('film_actions:film_actions.watchlist.tooltip_added'),
      );
      const watchListItemResponse = await addToWatchList(httpContext, film.id);
      dispatch(addFilmToWatchList(watchListItemResponse.data));
      trackSnowplowClickEvent({
        data: {
          clickType: 'film_action_watchlist',
          element: snowplowElement,
        },
        film,
      });
    }
  }, 500);

  const [isReady, isFilmInWatchList] = useIsFilmInWatchList(film.id);

  if (!isAuthenticated) {
    return null;
  }

  if (!isReady) {
    return null;
  }

  return children({
    isFilmInWatchList,
    doClickOnWatchListButton,
    tooltipMessage,
  });
};

export default WatchListButtonContainer;
