import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { FilmId } from '@app/api/resources/Film';
import { getWatchListByFilmIds } from '@app/api/resources/WatchList';

import { hasOwnProperty } from '@app/services/utils';

import { addItemsToWatchList } from '@app/actions/UserActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const usePopulateUserWatchlistForFilms = (filmIds: FilmId[]) => {
  const { httpContext, watchList, isAuthenticated } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      watchList: state.user.watchList,
      isAuthenticated: state.user.isAuthenticated,
    }),
    shallowEqual,
  );

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const doPopulateWatchlistForFilmIds = async () => {
    setIsLoading(true);
    try {
      const watchlistForFilmIdResponse = await getWatchListByFilmIds(
        httpContext,
        filmIdsToInitialise,
      );

      if (watchlistForFilmIdResponse) {
        const { data: wishes } = watchlistForFilmIdResponse;

        const checkedWishes = filmIdsToInitialise.map(filmId => ({
          id: wishes.find(wish => wish.film_id === filmId)?.id || null,
          film: { id: filmId },
          isLoading: false,
        }));

        dispatch(addItemsToWatchList(checkedWishes));
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const filmIdsUniqueKey = filmIds ? [...filmIds].sort().join('-') : '';

  const filmIdsToInitialise = filmIds?.filter(
    filmId => !hasOwnProperty(watchList, filmId),
  );

  useEffect(() => {
    if (
      filmIdsUniqueKey &&
      isAuthenticated &&
      filmIdsToInitialise?.length > 0
    ) {
      doPopulateWatchlistForFilmIds();
    }
  }, [filmIdsUniqueKey]);

  return [watchList, isLoading, isError];
};

export default usePopulateUserWatchlistForFilms;
