import { ReactElement } from 'react';
import styled from '@emotion/styled';

type PitchBannerBaseProps = {
  headerText: string;
  onButtonClick: () => void;
  children: ReactElement;
};

const PitchBannerBase = ({
  headerText,
  onButtonClick,
  children,
}: PitchBannerBaseProps) => (
  <BannerContainer>
    <BannerHeader>{headerText}</BannerHeader>
    <BannerButtonContainer onClick={onButtonClick}>
      {children}
    </BannerButtonContainer>
  </BannerContainer>
);

export default PitchBannerBase;

const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  min-height: 125px;
  background: ${props => props.theme.color.white};
  color: ${props => props.theme.color.darkGray};
  line-height: 26px;
  margin: 0 15px;
  padding: 10px 0;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    min-height: 120px;
  }
`;

const BannerHeader = styled.p`
  text-align: center;
  font: inherit;
  font-size: 100%;
  font-size: 22px;
  margin: 0 15px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin: 0 25px;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: 24px;
  }
`;

const BannerButtonContainer = styled.div`
  margin-top: 7px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 14px;
  }
`;
