import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import { isWatchableBySubscribers } from '@app/api/resources/Consumables';
import { Film } from '@app/api/resources/Film';
import { FilmGroupId } from '@app/api/resources/FilmGroup';

import {
  checkPathnameArrayIncludesPathname,
  checkPathnamesAreEqual,
} from '@app/services/routeHelpers';

import { ObjectOfAny } from '@app/types/utility-types';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import PitchBannerNotWatchable from '@app/components/pitch-banner/PitchBannerNotWatchable';
import PitchBannerStartWatching from '@app/components/pitch-banner/PitchBannerStartWatching';
import PitchBannerWatchable from '@app/components/pitch-banner/PitchBannerWatchable';

const shouldHidePitchBannerOnFilmDetailsPage = (
  film: Film,
  pathname: string,
  isAuthenticated: boolean,
  onboardingOfferActive: boolean,
  watchableBySubscribers: boolean,
) => {
  if (checkPathnameArrayIncludesPathname(['/films/[filmSlug]'], pathname)) {
    // Hide PitchBanner on film details page if film is Mubi Go Highlighted (mubi go ticket is shown)
    const isMubiGoHighlighted = film?.mubi_go_highlighted;

    // Hide PitchBanner on film details page if user is not authenticated
    // or if user is authenticated and onboarding offer is active and film is watchable by subscribers
    // (that means the subscribe button is shown on the page, so no need to show the banner at the top)
    const isOnboardingCTAShownOnThePage =
      watchableBySubscribers && (!isAuthenticated || onboardingOfferActive);

    return isMubiGoHighlighted || isOnboardingCTAShownOnThePage;
  }

  return false;
};

type PitchBannerProps = {
  film?: Film;
  filmGroupId?: FilmGroupId;
};

const PitchBanner = ({ film, filmGroupId }: PitchBannerProps) => {
  const { trialsEnabled, onboardingPromo, isAuthenticated } = useAppSelector(
    state => ({
      trialsEnabled: state.appState.trialsEnabled,
      onboardingPromo: state.appState.onboardingConfig?.promo,
      isAuthenticated: state.user.isAuthenticated,
    }),
    shallowEqual,
  );
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const watchableBySubscribers = isWatchableBySubscribers(film?.consumable);
  const onboardingOfferActive = !!onboardingPromo;

  let extraData = {} as ObjectOfAny;
  if (film?.id) {
    extraData.film_id = film.id;
  }
  if (filmGroupId) {
    extraData.film_group_id = filmGroupId;
  }

  const router = useRouter();

  if (
    checkPathnameArrayIncludesPathname(
      [
        '/films/[filmSlug]',
        '/films/[filmSlug]/trailer',
        '/films/[filmSlug]/critics-reviews',
        '/films/[filmSlug]/ratings',
        '/lists/[...params]',
      ],
      router.pathname,
    )
  ) {
    if (
      shouldHidePitchBannerOnFilmDetailsPage(
        film,
        router.pathname,
        isAuthenticated,
        onboardingOfferActive,
        watchableBySubscribers,
      )
    ) {
      return null;
    }

    return watchableBySubscribers ? (
      <PitchBannerWatchable
        trialsEnabled={trialsEnabled}
        onButtonClick={() =>
          trackSnowplowClickEvent({
            data: {
              clickType: 'pitch_banner',
              element: 'cta_watchable_film',
            },
            extraData,
            film,
          })
        }
      />
    ) : (
      <PitchBannerNotWatchable
        onButtonClick={() =>
          trackSnowplowClickEvent({
            data: {
              clickType: 'pitch_banner',
              element: 'cta_not_watchable_film',
            },
            extraData,
            film,
          })
        }
      />
    );
  }

  if (
    checkPathnamesAreEqual(router.pathname, '/collections/[collectionSlug]')
  ) {
    return (
      <PitchBannerStartWatching
        trialsEnabled={trialsEnabled}
        onButtonClick={() =>
          trackSnowplowClickEvent({
            data: {
              clickType: 'pitch_banner',
              element: 'cta_start_watching',
            },
            extraData,
            film,
          })
        }
      />
    );
  }

  return null;
};

export default PitchBanner;
