import HttpService from '@app/api/http-service';
import { MetaCursor } from '@app/api/pagination';
import { Film, FilmId } from '@app/api/resources/Film';
import { ResponseWithError } from '@app/api/resources/Responses';
import { ObjectOfStrings } from '@app/api/utility-types';
import { getCursorPaginationQS } from '@app/api/utils';

export type WatchListItem = {
  id: number;
  film: Film;
};

type SlimWatchListItem = {
  id: number;
  film_id: FilmId;
};

export type UsersWatchListItem = {
  wishId: number;
  isLoading: boolean;
};

type UsersWatchList = {
  [key: FilmId]: UsersWatchListItem;
};

export type WishResponse = {
  wishes: WatchListItem[];
  meta: MetaCursor;
};

export const getWatchListAllFilms = (
  httpContext: ObjectOfStrings,
  currentCursor: string,
  perPage: number,
): ResponseWithError<WishResponse> =>
  HttpService(httpContext).get(
    `/wishes?${getCursorPaginationQS(currentCursor, perPage)}`,
  );

export const getWatchListAvailableFilms = (
  httpContext: ObjectOfStrings,
  currentCursor: string,
  perPage: number,
): ResponseWithError<{
  wishes: WatchListItem[];
  meta: MetaCursor;
}> =>
  HttpService(httpContext).get(
    `/wishes/available?${getCursorPaginationQS(currentCursor, perPage)}`,
  );

export const getWatchListUnavailableFilms = (
  httpContext: ObjectOfStrings,
  currentCursor: string,
  perPage: number,
): ResponseWithError<{
  wishes: WatchListItem[];
  meta: MetaCursor;
}> =>
  HttpService(httpContext).get(
    `/wishes/unavailable?${getCursorPaginationQS(currentCursor, perPage)}`,
  );

export const addToWatchList = (
  httpContext,
  filmId,
): ResponseWithError<WatchListItem> =>
  HttpService(httpContext).post('/wishes', {
    data: {
      film_id: filmId,
    },
  });

export const removeFromWatchList = (
  httpContext: ObjectOfStrings,
  watchListItemId: number,
): Promise<{
  status: 204;
}> => HttpService(httpContext).deleteRequest(`/wishes/${watchListItemId}`);

export const getWatchListByFilmIds = (
  httpContext: ObjectOfStrings,
  filmIds: number[] = [],
): { data: SlimWatchListItem[] } => {
  const filmIdsString = filmIds.reduce((theFilmIdString, currFilmId) => {
    let newFilmIdString = `${theFilmIdString}`;
    if (newFilmIdString.length > 0) {
      newFilmIdString = `${newFilmIdString}&`;
    }
    newFilmIdString = `${newFilmIdString}film_ids[]=${currFilmId}`;
    return newFilmIdString;
  }, '');

  if (filmIdsString.length > 0) {
    return HttpService(httpContext).get(`/wishes/lookup?${filmIdsString}`);
  }
  return null;
};

export const getWatchListItemForFilmId = (
  filmId: number,
  watchList: UsersWatchList,
) => watchList[filmId];

export const getWishesOfUser = ({
  httpContext,
  userId,
  currentCursor,
  perPage,
}: {
  httpContext: ObjectOfStrings;
  userId: number;
  currentCursor: string;
  perPage: number;
}): ResponseWithError<{
  wishes: WatchListItem[];
  meta: MetaCursor;
}> =>
  HttpService(httpContext).get(
    `/users/${userId}/wishes?${getCursorPaginationQS(currentCursor, perPage)}`,
  );

export const hasUserWatchListedAtLeastOneFilm = async (
  httpContext: ObjectOfStrings,
  usersWatchlist: UsersWatchList,
): Promise<boolean> => {
  if (
    Object.values(usersWatchlist).find(watchlistItem => watchlistItem.wishId)
  ) {
    return true;
  }
  const watchListPageOneResponse = await getWatchListAllFilms(
    httpContext,
    null,
    1,
  );
  return watchListPageOneResponse.data.wishes.length > 0;
};
