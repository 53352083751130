import addDate from 'date-fns/add';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import parseISO from 'date-fns/parseISO';
import { setCookie } from 'nookies';

import { dismissABanner } from '@app/actions/UserActions';

const DAYS_UNTIL_SHOW_A_SECOND_TIME = 3;

const getBannerCookieName = (cookiePrefix: string) => {
  if (cookiePrefix) {
    return {
      firstDismissCookieName: `${cookiePrefix}BannerFirstDismissDateTime`,
      secondDismissCookieName: `${cookiePrefix}BannerSecondDismissDateTime`,
    };
  }
  return {};
};

const hasUserDismissedBanner = (
  firstDismissCookieDateTime: string,
  secondDismissCookieDateTime: string,
  longerDismissalTime?: number,
) => {
  if (secondDismissCookieDateTime) {
    return true;
  }
  if (firstDismissCookieDateTime) {
    const daysSinceFirstDismissal = differenceInDays(
      new Date(),
      parseISO(firstDismissCookieDateTime),
    );
    if (longerDismissalTime) {
      if (daysSinceFirstDismissal < longerDismissalTime) {
        return true;
      }
    }
    if (daysSinceFirstDismissal < DAYS_UNTIL_SHOW_A_SECOND_TIME) {
      return true;
    }
  }

  return false;
};

export const onBannerDismiss = (
  firstDismissCookieDateTime: string,
  cookiePrefix: string,
  dispatch,
) => {
  const { firstDismissCookieName, secondDismissCookieName } =
    getBannerCookieName(cookiePrefix);

  if (!firstDismissCookieDateTime) {
    const firstDismissTime = new Date().toISOString();
    setCookie(null, firstDismissCookieName, firstDismissTime, {
      maxAge: differenceInSeconds(
        addDate(new Date(), {
          years: 1,
        }),
        new Date(),
      ),
      path: '/',
    });
    dispatch(dismissABanner(firstDismissCookieName, firstDismissTime));
  } else {
    const secondDismissTime = new Date().toISOString();
    setCookie(null, secondDismissCookieName, secondDismissTime, {
      maxAge: differenceInSeconds(
        addDate(new Date(), {
          years: 1,
        }),
        new Date(),
      ),
      path: '/',
    });
    dispatch(dismissABanner(secondDismissCookieName, secondDismissTime));
  }
};

export const getDismissedBannerCookies = (
  cookiePrefix: string,
  dismissedBanners: {
    [key: string]: string;
  },
) => ({
  firstDismissCookieDateTime:
    dismissedBanners?.[`${cookiePrefix}BannerFirstDismissDateTime`],
  secondDismissCookieDateTime:
    dismissedBanners?.[`${cookiePrefix}BannerSecondDismissDateTime`],
});

export const checkIfUserDismissedBanner = (
  cookiePrefix: string,
  dismissedBanners: {
    [key: string]: string;
  },
) => {
  const { firstDismissCookieDateTime, secondDismissCookieDateTime } =
    getDismissedBannerCookies(cookiePrefix, dismissedBanners);

  return hasUserDismissedBanner(
    firstDismissCookieDateTime,
    secondDismissCookieDateTime,
  );
};
