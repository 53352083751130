import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { color as themeColors, ColorHex } from '@app/themes/mubi-theme';

import CloseButton from '@app/components/buttons/CloseButton';
import Countdown from '@app/components/Countdown';
import PageSection from '@app/components/layout/PageSection';

type PromotionalBannerProps = {
  children: ReactNode;
  onDismiss?: () => void;
  onPromoLinkClick?: () => void;
  hideDismissButton?: boolean;
  showCountdown?: boolean;
  wholeBannerLink?: boolean;
  countdownDate?: Date;
  bgColor?: ColorHex | ColorHex[];
  fontColor?: string;
  countdownColor?: string;
  singleLineDesktopLayout?: boolean;
  underLineLink?: boolean;
};

const PromotionalBanner = ({
  children,
  onDismiss = () => {},
  showCountdown = false,
  wholeBannerLink = null,
  onPromoLinkClick = null,
  countdownDate = new Date(),
  bgColor = themeColors.black,
  fontColor = themeColors.white,
  countdownColor = null,
  singleLineDesktopLayout = true,
  hideDismissButton = false,
  underLineLink = false,
}: PromotionalBannerProps) => (
  <Container bgColor={bgColor} fontColor={fontColor}>
    <PageSection>
      <InnerContainer>
        <ClickContainer
          hideDismissButton={hideDismissButton}
          wholeBannerLink={wholeBannerLink}
          onClick={wholeBannerLink ? onPromoLinkClick : undefined}
          singleLineDesktopLayout={singleLineDesktopLayout}
        >
          <TextContainer underLineLink={underLineLink}>
            {children}
          </TextContainer>
          {showCountdown && (
            <StyledCountdown date={countdownDate} color={countdownColor} />
          )}
        </ClickContainer>
        {!hideDismissButton && (
          <CloseButtonContainer>
            <CloseButton handleClose={onDismiss} color={fontColor} />
          </CloseButtonContainer>
        )}
      </InnerContainer>
    </PageSection>
  </Container>
);

const Container = styled.div<{
  bgColor: ColorHex | ColorHex[];
  fontColor: string;
}>`
  ${({ bgColor }) =>
    Array.isArray(bgColor)
      ? css`
          background: linear-gradient(
            90deg,
            ${bgColor[0]} 0%,
            ${bgColor[1]} 100%
          );
        `
      : css`
          background-color: ${bgColor};
        `}
  color: ${props => props.fontColor};
`;

const InnerContainer = styled.div`
  position: relative;
`;

const singleLineDesktopLayoutStyles = props => css`
  @media (min-width: ${props.theme.mqNew.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    margin: ${!props.hideDismissButton ? '0 30px 0 0' : '0'};
  }
`;
const ClickContainer = styled.div<{
  wholeBannerLink: boolean;
  singleLineDesktopLayout: boolean;
  hideDismissButton: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 14px 0 13px;
  margin: 0;
  cursor: ${props => (props.wholeBannerLink ? 'pointer' : 'auto')};
  ${props => props.singleLineDesktopLayout && singleLineDesktopLayoutStyles}
`;

const TextContainer = styled.div<{
  underLineLink: boolean;
}>`
  padding-right: 25px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: ${props => (props.underLineLink ? 'underline' : 'none')};
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding: 0;
    margin-bottom: 0;
    br {
      display: none;
    }
  }
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 0;
  z-index: 1;
`;

const StyledCountdown = styled(Countdown)`
  margin: 10px 0 0;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    margin: 0;
  }
`;

export default PromotionalBanner;
