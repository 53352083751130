import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { onBannerDismiss } from '@app/services/banners/banner-cookies';

import useHasDismissedBanner from '@app/hooks/helpers/useHasDismissedBanner';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

type GenericBannerContainerProps = {
  children: (props: {
    doBannerDismiss: () => void;
    doBannerClick: (onwardRoute?: string, openNewWindow?: boolean) => void;
    hasBannerBeenDismissed: boolean;
  }) => JSX.Element;
  cookiePrefix: string;
  snowplowElement?: string;
  snowplowExtraData?: Record<string, any>;
};

const GenericBannerContainer = ({
  children,
  cookiePrefix,
  snowplowElement,
  snowplowExtraData = {},
}: GenericBannerContainerProps) => {
  const [hasBannerBeenDismissed, setHasBannerBeenDismissed] = useState(false);
  const trackSnowplowClickEvent = useSnowplowClickTracker();
  const router = useRouter();
  const dispatch = useDispatch();

  const [hasUserPreviouslyDismissedBanner, dismissedBannerCookies] =
    useHasDismissedBanner(cookiePrefix);

  const doBannerDismiss = () => {
    onBannerDismiss(
      dismissedBannerCookies?.firstDismissCookieDateTime,
      cookiePrefix,
      dispatch,
    );
    setHasBannerBeenDismissed(true);

    const snowplowEvent = {
      clickType: 'banner_dismiss',
      element: snowplowElement,
    };
    if (snowplowElement) {
      snowplowEvent.element = snowplowElement;
    }
    trackSnowplowClickEvent({
      data: snowplowEvent,
      extraData: {
        banner_type: cookiePrefix,
        ...snowplowExtraData,
      },
    });
  };

  const doBannerClick = (onwardRoute = '', openNewWindow = false) => {
    const snowplowEvent = {
      clickType: 'banner_link',
      element: null,
    };
    if (snowplowElement) {
      snowplowEvent.element = snowplowElement;
    }
    trackSnowplowClickEvent({
      data: snowplowEvent,
      extraData: {
        banner_type: cookiePrefix,
        ...snowplowExtraData,
      },
    });
    if (onwardRoute) {
      if (openNewWindow) {
        window.open(onwardRoute, '_blank').focus();
      } else {
        router.push(onwardRoute);
      }
    }
  };

  const isShowing = !(
    hasBannerBeenDismissed || hasUserPreviouslyDismissedBanner
  );

  if (isShowing) {
    return children({ doBannerDismiss, doBannerClick, hasBannerBeenDismissed });
  }

  return null;
};

export default GenericBannerContainer;
