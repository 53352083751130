import useTranslation from 'next-translate/useTranslation';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import styled from '@emotion/styled';

type CountdownProps = {
  date?: Date;
  color?: string;
  className?: string;
};

const Countdown = ({
  date = new Date(),
  color = null,
  className = '',
}: CountdownProps) => {
  const { t } = useTranslation('promo');
  const theDate = calculateCountdown(date);

  return (
    <Container className={className}>
      <div>
        <TimePeriodNumber color={color}>
          <CalendarLine useWhiteColor={Boolean(color)} />
          <span>{addLeadingZeros(theDate.days)}</span>
        </TimePeriodNumber>
        <TimePeriodLabel color={color}>
          {t('promo:promo.countdown.day', {
            count: theDate.days,
          })}
        </TimePeriodLabel>
      </div>

      <div>
        <TimePeriodNumber color={color}>
          <CalendarLine useWhiteColor={Boolean(color)} />
          <span>{addLeadingZeros(theDate.hours)}</span>
        </TimePeriodNumber>
        <TimePeriodLabel color={color}>
          {t('promo:promo.countdown.hour', {
            count: theDate.hours,
          })}
        </TimePeriodLabel>
      </div>

      <div>
        <TimePeriodNumber color={color}>
          <CalendarLine useWhiteColor={Boolean(color)} />
          <span>{addLeadingZeros(theDate.min)}</span>
        </TimePeriodNumber>
        <TimePeriodLabel color={color}>
          {t('promo:promo.countdown.minute', {
            count: theDate.min,
          })}
        </TimePeriodLabel>
      </div>
    </Container>
  );
};

export default Countdown;

const calculateCountdown = (
  endDate: Date,
): { days: number; hours: number; min: number } => {
  const diffInDays = differenceInDays(endDate, new Date());
  const endDateMinusDays = subDays(endDate, diffInDays);
  const diffInHours = differenceInHours(endDateMinusDays, new Date());
  const endDateMinusDaysAndHours = subHours(endDateMinusDays, diffInHours);
  const diffInMinutes = differenceInMinutes(
    endDateMinusDaysAndHours,
    new Date(),
  );

  return {
    days: diffInDays,
    hours: diffInHours,
    min: diffInMinutes,
  };
};

const addLeadingZeros = (value: number): string => {
  let stringifiedValue = String(value);
  while (stringifiedValue.length < 2) {
    stringifiedValue = `0${stringifiedValue}`;
  }
  return stringifiedValue;
};

const Container = styled.div`
  display: inline-flex;
  width: 190px;
  justify-content: space-between;
`;

const TimePeriodLabel = styled.div<{ color: string }>`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${props => props.color || props.theme.color.white};
`;

const TimePeriodNumber = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 26px;
  font-weight: bold;
  color: ${props =>
    props.color ? props.theme.color.white : props.theme.color.black};
  background-color: ${props => props.color || props.theme.color.white};
  border-radius: 5px;
  min-width: 50px;
  height: 32px;
`;

const CalendarLine = styled.div<{ useWhiteColor: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  background-color: ${props =>
    props.useWhiteColor ? props.theme.color.white : props.theme.color.black};
  width: 100%;
  height: 1px;
`;
