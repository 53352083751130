import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

import UserStateBanner from '@app/components/banners/UserStateBanner';
import Link from '@app/components/Link';
import TranslateHtml from '@app/components/TranslateHtml';

const FailedPaymentBanner = () => {
  const { t } = useTranslation();
  const i18nUrlData = useI18nUrlData();

  const ctaUrl = getFullRouteUrl({
    url: '/payment_method/new',
    i18nUrlData,
  });

  return (
    <UserStateBanner
      messageComponent={
        <>
          {t('common:common.banners.failed_payment.banner_text')}{' '}
          <TranslateHtml
            t={t}
            i18nKey="common:common.banners.failed_payment.cta"
            i18nVars={{
              update_payment_details_text: (
                <LinkText href={ctaUrl} prefetch>
                  {t(
                    'common:common.banners.failed_payment.update_payment_details_text',
                  )}
                </LinkText>
              ),
            }}
          />
        </>
      }
      messageType="alert"
      allowDismiss={false}
    />
  );
};

export default FailedPaymentBanner;

const LinkText = styled(Link)`
  text-decoration: none !important; // override banner link underline rule
  &:hover {
    text-decoration: underline !important;
  }
`;
