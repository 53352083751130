import {
  BannersOnPages,
  DismissibleBannerConfiguration,
  NonDismissibleBannerConfiguration,
} from '@app/services/banners/banners';

import FailedPaymentBanner from '@app/components/banners/FailedPaymentBanner';
import PitchBanner from '@app/components/pitch-banner/PitchBanner';
import CDMExpiryWarningBanner from '@app/components/promotional-banner/banner-content/CDMExpiryWarningBanner';
import FlashSaleBanner from '@app/components/promotional-banner/banner-content/FlashSaleBanner';
import TheSubstanceBanner from '@app/components/promotional-banner/banner-content/TheSubstanceBanner';

// Info here: https://docs.google.com/spreadsheets/d/15wHB4UsO7FhpdZMlTTYvBQel66u80gRUf_RzxTTasmQ/edit?gid=1500590971#gid=1500590971
const restrictedCountriesTheSubstance = [
  'AS',
  'AR',
  'AT',
  'BS',
  'BZ',
  'BM',
  'BO',
  'BR',
  'IO',
  'CA',
  'KY',
  'CL',
  'CO',
  'CR',
  'CY',
  'EC',
  'SV',
  'FK',
  'DE',
  'GI',
  'GU',
  'GT',
  'GG',
  'GY',
  'HN',
  'IN',
  'IE',
  'IM',
  'JE',
  'MT',
  'MH',
  'MX',
  'FM',
  'NI',
  'MP',
  'PW',
  'PA',
  'PY',
  'PE',
  'PN',
  'PR',
  'SH',
  'GS',
  'TR',
  'TC',
  'GB',
  'US',
  'UM',
  'UY',
  'VE',
  'VI',
];

const pitchBanner: NonDismissibleBannerConfiguration = {
  bannerType: 'Pitch Banner',
  isDismissible: false,
  bannerComponent: ({ film, filmGroupId }) => (
    <PitchBanner film={film} filmGroupId={filmGroupId} />
  ),
  restrictions: {
    unsubscribedOnly: true,
  },
};

const theSubstanceBanner: DismissibleBannerConfiguration = {
  bannerType: 'THE SUBSTANCE BANNER',
  bannerComponent: ({ cookiePrefix }) => (
    <TheSubstanceBanner cookiePrefix={cookiePrefix} />
  ),
  isDismissible: true,
  cookiePrefix: 'the_substance_release_banner',
  startShowingOn: '2024-10-24 00:00:00',
  stopShowingOn: '2024-10-31 00:00:00',
  restrictions: {
    geoLocation: restrictedCountriesTheSubstance,
  },
};

const failedPaymentBanner: NonDismissibleBannerConfiguration = {
  bannerType: 'Failed payment banner',
  isDismissible: false,
  bannerComponent: () => <FailedPaymentBanner />,
  restrictions: {
    failedPaymentOnly: true,
  },
};

const flashSaleBanner: DismissibleBannerConfiguration = {
  bannerType: 'Flash Sale Banner Nov 2024',
  isDismissible: true,
  startShowingOn: '2024-11-08',
  stopShowingOn: '2024-11-17 23:59:59',
  cookiePrefix: 'global_fixed_discount_flash_nov_2024',
  showAgainAfterXDays: 2,
  bannerComponent: ({ cookiePrefix, stopShowingOn }) => (
    <FlashSaleBanner endDate={stopShowingOn} cookiePrefix={cookiePrefix} />
  ),
  restrictions: {},
};

const cdmBrowserWarningBanner: DismissibleBannerConfiguration = {
  bannerType: 'CDM revocation browser version warning banner',
  isDismissible: true,
  cookiePrefix: 'cdm_revocation_browser_warning_2024',
  bannerComponent: ({ cookiePrefix }) => (
    <CDMExpiryWarningBanner
      dueToBrowserOrWindowsVersions="browser"
      cookiePrefix={cookiePrefix}
    />
  ),
  restrictions: {
    isCDMRevokedDueToBrowserVersion: true,
  },
};

const cdmWindowsWarningBanner: DismissibleBannerConfiguration = {
  bannerType: 'CDM revocation Windows 7 or 8 warning banner',
  isDismissible: true,
  cookiePrefix: 'cdm_revocation_windows_warning_2024',
  bannerComponent: ({ cookiePrefix }) => (
    <CDMExpiryWarningBanner
      dueToBrowserOrWindowsVersions="windows"
      cookiePrefix={cookiePrefix}
    />
  ),
  restrictions: {
    isCDMRevokedDueToWindowsVersion: true,
  },
};

// The banners are in priority order for each pathname.
export const bannersOnPages: BannersOnPages = {
  '/': [flashSaleBanner],
  '/showing': [
    cdmBrowserWarningBanner,
    cdmWindowsWarningBanner,
    flashSaleBanner,
    theSubstanceBanner,
    failedPaymentBanner,
  ],
  '/films/[filmSlug]': [
    cdmBrowserWarningBanner,
    cdmWindowsWarningBanner,
    flashSaleBanner,
    pitchBanner,
    failedPaymentBanner,
  ],
  '/notebook': [flashSaleBanner, pitchBanner],
  '/collections/[collectionSlug]': [
    cdmBrowserWarningBanner,
    cdmWindowsWarningBanner,
    flashSaleBanner,
    pitchBanner,
    failedPaymentBanner,
  ],
};
