import { useEffect, useState } from 'react';

import {
  checkIfUserDismissedBanner,
  getDismissedBannerCookies,
} from '@app/services/banners/banner-cookies';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type UseHasDismissedBannerReturnTypes = [
  boolean,
  {
    firstDismissCookieDateTime: string;
    secondDismissCookieDateTime: string;
  },
];

const useHasDismissedBanner = (
  bannerPrefix: string,
): UseHasDismissedBannerReturnTypes => {
  const dismissedBanners = useAppSelector(state => state.user.dismissedBanners);

  const [hasDismissedBanner, setHasDismissedBanner] = useState(
    checkIfUserDismissedBanner(bannerPrefix, dismissedBanners),
  );
  const [dismissedBannerCookies, setHasDismissedBannerCookies] = useState(
    getDismissedBannerCookies(bannerPrefix, dismissedBanners),
  );

  useEffect(() => {
    if (bannerPrefix) {
      setHasDismissedBannerCookies(
        getDismissedBannerCookies(bannerPrefix, dismissedBanners),
      );
      setHasDismissedBanner(
        checkIfUserDismissedBanner(bannerPrefix, dismissedBanners),
      );
    } else {
      setHasDismissedBanner(null);
    }
  }, [bannerPrefix]);

  return [hasDismissedBanner, dismissedBannerCookies];
};

export default useHasDismissedBanner;
