import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { bannersOnPages } from '@app/services/banners/banner-configuration';
import {
  BannerConfiguration,
  shouldShowBanner,
} from '@app/services/banners/banners';
import { stripVanityPrefix } from '@app/services/routeHelpers';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useGetBannerToShowForPathname = (
  pathname: string,
): BannerConfiguration => {
  const {
    dismissedBanners,
    geoLocation,
    currentLanguage,
    countryFullTimeZone,
    magazineSubStatus,
    user,
    detectedBrowser,
    detectedBrowserVersion,
    detectedOperatingSystem,
  } = useAppSelector(
    state => ({
      dismissedBanners: state.user.dismissedBanners,
      geoLocation: state?.user.geoLocation,
      currentLanguage: state?.user.currentLanguage,
      countryFullTimeZone: state?.user.countryFullTimeZone,
      magazineSubStatus: state?.user.magazineSubStatus,
      user: state.user.user,
      detectedBrowser: state.appState.detectedBrowser,
      detectedBrowserVersion: state.appState.detectedBrowserVersion,
      detectedOperatingSystem: state.appState.detectedOperatingSystem,
    }),
    shallowEqual,
  );

  const getBannerToShowForPathname = (): BannerConfiguration => {
    let bannerToShowForPath = null;

    const pathnameWithoutVanityPrefix = stripVanityPrefix(pathname);

    const bannersForPath =
      bannersOnPages?.[
        pathnameWithoutVanityPrefix === '/[vanityPath]'
          ? '/'
          : pathnameWithoutVanityPrefix
      ];

    if (bannersForPath) {
      bannerToShowForPath = bannersForPath.find(banner =>
        shouldShowBanner({
          banner,
          dismissedBanners,
          countryFullTimeZone,
          user,
          geoLocation,
          currentLanguage,
          magazineSubStatus,
          detectedBrowser,
          detectedBrowserVersion,
          detectedOperatingSystem,
        }),
      );
    }
    return bannerToShowForPath;
  };

  const [bannerToShow, setBannerToShow] = useState<BannerConfiguration>(
    getBannerToShowForPathname(),
  );

  useEffect(() => {
    setBannerToShow(getBannerToShowForPathname());
  }, [pathname, user?.active_subscriber]);

  if (bannerToShow) {
    return bannerToShow;
  }
  return null;
};

export default useGetBannerToShowForPathname;
