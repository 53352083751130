import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import parseISO from 'date-fns/parseISO';

import { Translate } from '@app/api/utility-types';

import { LocalePath } from '@app/api/services/language';
import { formatDate } from '@app/services/date-format';

const LEAVING_SOON_DAYS = 14;
const COMING_SOON_DAYS = 30;
const COMING_SOON_DAYS_EPISODE = 90;

type Offer = {
  type: 'catalogue' | 'free';
  download_availability: boolean;
};

type ComingMessage = {
  detail: {
    type: 'default' | 'emphasized';
    text: string;
  }[];
  tile: {
    type: 'coming';
    text: string;
  } | null;
};

type LeavingMessage = {
  detail: {
    type: 'expiring';
    text: string;
  }[];
  tile: {
    type: 'expiring';
    text: string;
  } | null;
};

export type Consumable = {
  film_id: number;
  available_at: string;
  // When the film is no longer available on the platform (Use this one)
  availability_ends_at: string;
  // When the user is no longer able to watch the film
  /* (expires_at is availability_ends_at + 8 hours.  But that’s the
      true expiration of a film. So at midnight and 1 sec if you got
      to an expired film and you’re on the film page and refresh you
      can still watch it even through it just left) */
  expires_at: string;
  exclusive: boolean;
  availability: 'live' | 'upcoming';
  offered: Offer[];
  film_date_message?: ComingMessage | LeavingMessage;
  permit_download: boolean;
};

export type ConsumableKeyedByFilmId = {
  [key: number]: Consumable;
};

export const offersConsumableOfType = (
  consumable: Consumable,
  type: string,
) => {
  const offered = consumable?.offered;
  if (offered) {
    return Boolean(offered.find(offer => offer.type === type));
  }
  return false;
};

export const isLeavingSoon = (consumable: Consumable) => {
  const daysTillLeaving = isLeavingInHowManyDays(consumable);
  if (daysTillLeaving !== false) {
    return daysTillLeaving <= LEAVING_SOON_DAYS;
  }
  return false;
};

export const isLeavingInHowManyDays = (consumable: Consumable) => {
  const availabilityEndsAtDate = consumable?.availability_ends_at;
  if (availabilityEndsAtDate) {
    return differenceInDays(parseISO(availabilityEndsAtDate), new Date());
  }
  return false;
};

const getComingSoonNumDays = (consumable: Consumable, isEpisode?: boolean) => {
  const parsedAvailableAtDate = parseISO(consumable?.available_at);
  const diffInDays = differenceInHours(parsedAvailableAtDate, new Date()) / 24;
  const comingSoonDays = isEpisode
    ? COMING_SOON_DAYS_EPISODE
    : COMING_SOON_DAYS;
  if (diffInDays > 0 && diffInDays <= comingSoonDays) {
    return Math.ceil(diffInDays);
  }

  return null;
};

export const isComingSoon = (consumable: Consumable, isEpisode?: boolean) => {
  const comingSoonNumDays = getComingSoonNumDays(consumable, isEpisode);
  if (!comingSoonNumDays) {
    return false;
  }
  const comingSoonDays = isEpisode
    ? COMING_SOON_DAYS_EPISODE
    : COMING_SOON_DAYS;
  return comingSoonNumDays <= comingSoonDays;
};

export const getComingSoonText = (
  consumable: Consumable,
  t: Translate,
  currentLanguage: LocalePath,
) => {
  const comingSoonNumDays = getComingSoonNumDays(consumable);
  if (comingSoonNumDays) {
    const formattedDate = formatDate(
      parseISO(consumable?.available_at),
      'd LLLL',
      currentLanguage,
    );
    return t('common:common.film_labels.coming_on_date', {
      date: formattedDate,
    });
  }
  return null;
};

export const isLeavingOrComingSoon = (
  consumable: Consumable,
  isEpisode?: boolean,
) => {
  const leavingSoon = isLeavingSoon(consumable);
  const comingSoon = isComingSoon(consumable, isEpisode);
  return leavingSoon || comingSoon;
};

export const isWatchableBySubscribers = (consumable: Consumable) =>
  !!consumable?.offered?.find(offeredConsumable =>
    ['catalogue'].includes(offeredConsumable.type),
  );
