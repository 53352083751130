import parseISO from 'date-fns/parseISO';
import styled from '@emotion/styled';

import { isPastDate } from '@app/services/date-utils';

import { color } from '@app/themes/mubi-theme';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import CloseButton from '@app/components/buttons/CloseButton';
import Countdown from '@app/components/Countdown';
import PageSection from '@app/components/layout/PageSection';
import Link from '@app/components/Link';
import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';

const FlashSaleBanner = ({ endDate, cookiePrefix }) => {
  const promoPromoted = useAppSelector(state => state.appState.promoPromoted);

  if (promoPromoted) {
    return (
      <GenericBannerContainer
        cookiePrefix={cookiePrefix}
        snowplowElement="global_fixeddiscount_flash2024"
      >
        {({ doBannerDismiss, doBannerClick }) => (
          <PromoWrap
            onClick={() => {
              if (doBannerClick) {
                doBannerClick();
              }
            }}
          >
            <PageSection mobilePadding={false}>
              <PromoContent>
                <LinkStyled href={promoPromoted.url} />
                <HideOnDesktopUp>
                  <PromoTitle>{promoPromoted.title}</PromoTitle>
                </HideOnDesktopUp>
                <PromoBodyContent>
                  <TextContainer>
                    <PromoBody
                      dangerouslySetInnerHTML={{
                        __html: promoPromoted.body,
                      }}
                    ></PromoBody>{' '}
                    <LinkCta href={promoPromoted.url}>
                      <CtaCopy>{promoPromoted.cta}</CtaCopy>
                    </LinkCta>
                  </TextContainer>
                  <HideOnDesktopDown>
                    <PromoTitle>{promoPromoted.title}</PromoTitle>
                  </HideOnDesktopDown>
                  <CountdownContainer>
                    {isPastDate('2024-11-15') ? (
                      <Countdown
                        date={parseISO(endDate)}
                        className="promo-countdown chromatic-ignore"
                      />
                    ) : null}
                    <CloseButtonContainer>
                      <CloseButton
                        handleClose={doBannerDismiss}
                        width="15px"
                        padding="13px"
                        color={color.lightBackground}
                      />
                    </CloseButtonContainer>
                  </CountdownContainer>
                </PromoBodyContent>
              </PromoContent>
            </PageSection>
          </PromoWrap>
        )}
      </GenericBannerContainer>
    );
  }

  return null;
};

export default FlashSaleBanner;

const PromoWrap = styled.div`
  width: 100%;
  display: flex;
  padding-block: 20px;
  justify-content: center;
  background-color: ${props => props.theme.color.darkBlue};
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding-block: 10px;
  }
`;

const PromoContent = styled.div`
  width: 100%;
  position: relative;
  font-family: ${props => props.theme.font.title};
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: 60px;
  }
`;

const PromoBodyContent = styled.section`
  text-align: center;
  height: 100%;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
`;

const TextContainer = styled.p`
  flex: 1;
  padding-block: 20px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding-block: 0;
  }
`;

const LinkStyled = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const LinkCta = styled(Link)`
  position: relative;
  z-index: 2;
  &:hover {
    color: ${props => props.theme.color.electricLime};
  }
`;

const PromoBody = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.white};
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 16px;
    display: block;
  }
  @media (min-width: ${props => props.theme.mqNew.wide}) {
    display: inline;
  }
`;

const CtaCopy = styled.span`
  color: ${props => props.theme.color.electricLime};
  text-decoration: underline;

  font-size: 14px;
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 16px;
  }
`;

const CloseButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.1s;
  width: 20px;
  height: 20px;
  border: solid 1px ${props => props.theme.color.lightBackground};
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 2;
  &:hover {
    opacity: 0.5;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    right: 0;
  }
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    position: relative;
    margin-left: 18px;
  }
`;

const PromoTitle = styled.h2`
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  color: ${props => props.theme.color.darkBlue};
  -webkit-text-stroke-width: 1px;
  -webkit-text-fill-color: ${props => props.theme.color.darkBlue};
  -webkit-text-stroke-color: ${props => props.theme.color.electricLime};
`;

const CountdownContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: baseline;
  .promo-countdown span {
    opacity: 0.7;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    justify-content: flex-end;
  }
`;

const HideOnDesktopUp = styled.div`
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: none;
  }
`;

const HideOnDesktopDown = styled.div`
  flex: 1;
  display: none;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: block;
  }
`;
