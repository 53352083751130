import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import {
  getSnowplowPageType,
  trackSnowplowBannerView,
} from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useSnowplowBannerViewTracking = () => {
  const router = useRouter();

  const { httpContext, countryCode, vanityPathPageType } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      countryCode: state.user.geoLocation,
      vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
    }),
    shallowEqual,
  );

  const doTrackSnowplowBannerView = async (eventData: {
    event_type: string;
    campaign: string;
    cta: 'sign_up' | 'add_to_watchlist';
  }) => {
    const pageType = await getSnowplowPageType(router, vanityPathPageType);
    trackSnowplowBannerView(
      {
        ...eventData,
        ...pageType,
        country_code: countryCode,
      },
      httpContext,
    );
  };

  return doTrackSnowplowBannerView;
};

export default useSnowplowBannerViewTracking;
