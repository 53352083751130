import useTranslation from 'next-translate/useTranslation';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import MubiButtonLink from '@app/components/buttons/MubiButton/MubiButtonLink';
import PitchBannerBase from '@app/components/pitch-banner/PitchBannerBase';

type PitchBannerNotWatchableProps = {
  onButtonClick: () => void;
};

const PitchBannerNotWatchable = ({
  onButtonClick,
}: PitchBannerNotWatchableProps) => {
  const { t } = useTranslation('common');

  const onboardingPromo = useAppSelector(
    state => state.appState.onboardingConfig?.promo,
  );

  const getPitchText = () => {
    let pitchText = t('common:common.pitch_banner.mubi_pitch_short');

    if (onboardingPromo) {
      const {
        type,
        plan_period,
        discount_period_days,
        amount_saved,
        discounted_price,
        variant,
      } = onboardingPromo;

      if (type === 'Discount') {
        if (plan_period === 'month') {
          pitchText = t(
            'common:common.pitch_banner.onboarding_offer.discount_months',
            {
              discount: amount_saved,
              month: discount_period_days / 30,
            },
          );
        } else if (plan_period === 'year') {
          pitchText = t(
            'common:common.pitch_banner.onboarding_offer.discount_year',
            {
              discount: amount_saved,
            },
          );
        }
      } else if (type === 'FixedDiscount') {
        if (variant === 'one_month') {
          pitchText = t(
            'common:common.pitch_banner.onboarding_offer.fixed_one_month',
            {
              price: discounted_price,
            },
          );
        } else if (variant === 'one_year') {
          pitchText = t(
            'common:common.pitch_banner.onboarding_offer.fixed_year',
            {
              price: discounted_price,
            },
          );
        } else {
          pitchText = t(
            'common:common.pitch_banner.onboarding_offer.fixed_months',
            {
              month: discount_period_days / 30,
              price: discounted_price,
            },
          );
        }
      }
    }
    return pitchText;
  };

  return (
    <PitchBannerBase onButtonClick={onButtonClick} headerText={getPitchText()}>
      <MubiButtonLink url="/showing">
        {t('common:common.pitch_banner.see_whats_playing')}
      </MubiButtonLink>
    </PitchBannerBase>
  );
};

export default PitchBannerNotWatchable;
