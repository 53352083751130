// eslint-disable-next-line simple-import-sort/imports
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import parseISO from 'date-fns/parseISO';

import { formatDateIntl } from '@app/services/date-format';
import { getFullRouteUrl } from '@app/services/routeHelpers';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';
import useOnboardingTrigger from '@app/hooks/helpers/useOnboardingTrigger';
import { usePopulateFilmForFilmId } from '@app/hooks/populate-store/usePopulateFilmForFilmId';
import usePopulateUserWatchlistForFilms from '@app/hooks/populate-store/usePopulateUserWatchlistForFilms';
import useSnowplowBannerViewTracking from '@app/hooks/snowplow/useSnowplowBannerViewTracking';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import CloseButton from '@app/components/buttons/CloseButton';
import FadeIn from '@app/components/FadeIn';
import WatchListButtonContainer from '@app/components/film-actions/WatchListButtonContainer';
import PageSection from '@app/components/layout/PageSection';
import TooltipPosition from '@app/components/tool-tip/TooltipPosition';
import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import TheSubstanceBannerContainer from '@app/components/promotional-banner/banner-containers/TheSubstanceBannerContainer';
import { shallowEqual } from 'react-redux';

type TheSubstanceBannerProps = {
  cookiePrefix: string;
};

const TheSubstanceBanner = ({ cookiePrefix }: TheSubstanceBannerProps) => {
  const { t, lang } = useTranslation('common');
  const [showBanner, setShowBanner] = useState(false);

  const onboardingTrigger = useOnboardingTrigger();
  const trackSnowplowBannerView = useSnowplowBannerViewTracking();
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const router = useRouter();
  const i18nUrlData = useI18nUrlData();

  const { isAuthenticated, currentLanguage } = useAppSelector(
    state => ({
      isAuthenticated: state?.user?.isAuthenticated,
      currentLanguage: state?.user?.currentLanguage,
    }),
    shallowEqual,
  );

  const [film] = usePopulateFilmForFilmId('the-substance');
  usePopulateUserWatchlistForFilms([film?.id]);

  useEffect(() => {
    const loadFont = async () => {
      try {
        const fontFace = new FontFace(
          'KCompress',
          `url(${process.env.assetPrefix}/static/fonts/K-Compress.woff2)`,
        );
        (document as any).fonts.add(fontFace);
        await fontFace.load();
        setShowBanner(true);
      } catch (e) {
        //ignore error and show banner the font is loaded via css anyway
        setShowBanner(true);
      }
    };

    loadFont();
  }, []);

  const triggerOnboarding = () => {
    trackSnowplowClickEvent({
      data: {
        clickType: 'sign_up',
        element: 'film_banner',
      },
      extraData: {
        film_id: film.id,
        banner_type: cookiePrefix,
        promo_campaign: cookiePrefix,
      },
    });
    onboardingTrigger('trialNew');
  };

  const getOpenQuoteSymbol = (currentLanguage: string): string => {
    switch (currentLanguage) {
      case 'de':
        return '„';
      case 'fr':
        return '«';

      default:
        return '"'; // Default symbol
    }
  };

  const getCloseQuoteSymbol = (currentLanguage: string): string => {
    switch (currentLanguage) {
      case 'de':
        return '”';
      case 'fr':
        return '»';

      default:
        return '"'; // Default symbol
    }
  };

  return (
    <TheSubstanceBannerContainer
      cookiePrefix={cookiePrefix}
      snowplowElement="film_banner"
      snowplowExtraData={{
        film_id: film?.id,
        promo_campaign: cookiePrefix,
      }}
    >
      {({ doBannerDismiss, doBannerClick }) => (
        <InView
          triggerOnce
          onChange={inView => {
            if (inView) {
              trackSnowplowBannerView({
                event_type: 'floating_banner_shown',
                campaign: cookiePrefix,
                cta: 'add_to_watchlist',
              });
            }
          }}
        >
          <PageSection relative fromBreakpoint="tablet">
            <BannerContainer>
              <FadeIn isShowing={showBanner}>
                <InnerContainer
                  onClick={() => {
                    doBannerClick(
                      getFullRouteUrl({
                        url: `/films/${film.slug}`,
                        i18nUrlData,
                      }),
                      false,
                      true,
                    );
                  }}
                >
                  <CloseContainer>
                    <CloseButton
                      handleClose={e => {
                        e.stopPropagation();
                        doBannerDismiss();
                      }}
                    />
                  </CloseContainer>
                  <TheSubstance>
                    {t('common:common.banners.the_substance.title')}
                  </TheSubstance>
                  <ReleaseDate isTurkishLanguage={currentLanguage === 'tr'}>
                    {t(
                      'common:common.banners.the_substance.mubi_release_date',
                      {
                        date: formatDateIntl(parseISO('2000-10-31'), lang, {
                          day: 'numeric',
                          month: 'long',
                        }),
                      },
                    )}
                  </ReleaseDate>
                  <Quote isTurkishLanguage={currentLanguage === 'tr'}>
                    {t('common:common.banners.the_substance.quote', {
                      open_quote_symbol: getOpenQuoteSymbol(currentLanguage),
                      close_quote_symbol: getCloseQuoteSymbol(currentLanguage),
                    })}
                  </Quote>
                </InnerContainer>

                {film &&
                  (!isAuthenticated ? (
                    <CTAContainer>
                      <CTAPlaceholder onClick={triggerOnboarding}>
                        {t('film_actions:film_actions.actions.watchlist_add')}
                      </CTAPlaceholder>
                    </CTAContainer>
                  ) : (
                    <WatchListButtonContainer
                      film={film}
                      snowplowElement="film_banner"
                    >
                      {({
                        isFilmInWatchList,
                        doClickOnWatchListButton,
                        tooltipMessage,
                      }) => {
                        const titleKey = isFilmInWatchList
                          ? 'watchlist_in'
                          : 'watchlist_add';
                        return (
                          <CTAContainer>
                            <TooltipPosition
                              message={tooltipMessage}
                              uniqId={`${cookiePrefix}_cta`}
                            >
                              <FadeIn isShowing>
                                <div
                                  onClick={() => {
                                    if (isFilmInWatchList) {
                                      trackSnowplowClickEvent({
                                        data: {
                                          clickType: 'watchlist_page_link',
                                          element: 'film_banner',
                                        },
                                        extraData: {
                                          film_id: film.id,
                                          banner_type: cookiePrefix,
                                          promo_campaign: cookiePrefix,
                                        },
                                      });
                                      doBannerDismiss(true);
                                      router.push(
                                        getFullRouteUrl({
                                          url: '/watchlist',
                                          i18nUrlData,
                                        }),
                                      );
                                    } else {
                                      doClickOnWatchListButton();
                                      doBannerDismiss(true);
                                    }
                                  }}
                                >
                                  {t(
                                    `film_actions:film_actions.actions.${titleKey}`,
                                  )}
                                </div>
                              </FadeIn>
                            </TooltipPosition>
                          </CTAContainer>
                        );
                      }}
                    </WatchListButtonContainer>
                  ))}
              </FadeIn>
            </BannerContainer>
          </PageSection>
        </InView>
      )}
    </TheSubstanceBannerContainer>
  );
};

export default TheSubstanceBanner;

const BannerContainer = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  cursor: pointer;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    position: absolute;
    z-index: 3;
    top: 90px;
    left: auto;
    right: 22px;
    width: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 160px;
  }
`;

const InnerContainer = styled.div`
  cursor: pointer;
`;

const sharedStyles = props => css`
  font-family: 'KCompress';
  line-height: 1;
  letter-spacing: 0.4px;
  text-align: center;
  background-color: ${props.theme.color.black};
  color: ${props.theme.color.white};
  width: 100%;
  text-transform: uppercase;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 6px;
  left: 10px;

  & button {
    border-radius: 50%;
    padding: 2px;
    background-color: transparent;
    transition: background-color 200ms ease-in-out;

    & svg {
      stroke: ${props => props.theme.color.black};
      width: 20px;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    position: relative;
    top: initial;
    left: initial;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 5px 6px 5px 11px;
    background-color: ${props => props.theme.color.black};

    & button {
      :hover {
        background-color: ${props => props.theme.color.darkText};
      }
      & svg {
        stroke: ${props => props.theme.color.white};
        width: 11px;
      }
    }
  }
`;

const TheSubstance = styled.div`
  ${sharedStyles}
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};

  font-size: 26px;
  padding: 4px 11px 0;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 33px;
    padding: 9px 11px 4px;
  }
`;

type TrProps = {
  isTurkishLanguage: boolean;
};

const ReleaseDate = styled.div<TrProps>`
  ${sharedStyles}
  line-height: 1;
  font-size: 46px;
  padding: 6px 11px 0;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    line-height: ${props => (props.isTurkishLanguage ? '0.85' : '0.8')};
    padding: 14px 8px 8px;
  }
`;

const Quote = styled.div<TrProps>`
  ${sharedStyles}
  line-height: 1;
  font-size: 17px;
  padding: 6px 11px 4px;
  border-top: 0.5px solid ${props => props.theme.color.white};
  border-bottom: 0.5px solid ${props => props.theme.color.white};
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    line-height: ${props => (props.isTurkishLanguage ? '0.95' : '0.9')};
    font-size: 28px;
    padding: 11px 12px 8px;
  }
`;

const CTAContainer = styled.div`
  ${sharedStyles}
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};
  font-size: 20px;
  padding: 6px 11px 4px;
  display: block;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 9px 8px 6px;
  }
`;

const CTAPlaceholder = styled.div``;
