import { useRouter } from 'next/router';

import { Film } from '@app/api/resources/Film';
import { FilmGroupId } from '@app/api/resources/FilmGroup';

import { NonDismissibleBannerComponent } from '@app/services/banners/banners';

import useGetBannerToShowForPathname from '@app/hooks/helpers/useGetBannerToShowForPathname';

type BannerContainerProps = {
  film?: Film;
  filmGroupId?: FilmGroupId;
};

const BannerContainer = ({
  film = null,
  filmGroupId = null,
}: BannerContainerProps): JSX.Element => {
  const router = useRouter();

  const bannerToShowForPathname = useGetBannerToShowForPathname(
    router.pathname,
  );

  if (bannerToShowForPathname && bannerToShowForPathname?.bannerComponent) {
    if (bannerToShowForPathname.isDismissible) {
      const BannerComponentForPathname =
        bannerToShowForPathname.bannerComponent;
      return (
        <BannerComponentForPathname
          cookiePrefix={bannerToShowForPathname.cookiePrefix}
          stopShowingOn={bannerToShowForPathname.stopShowingOn}
        />
      );
    } else {
      const BannerComponent =
        bannerToShowForPathname.bannerComponent as NonDismissibleBannerComponent;
      return <BannerComponent film={film} filmGroupId={filmGroupId} />;
    }
  }

  return null;
};

export default BannerContainer;
