import { useEffect, useState } from 'react';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useIsFilmInWatchList = (filmId: number): [boolean, boolean] => {
  const wish = useAppSelector(state => state.user.watchList[filmId]);
  const [isReady, setIsReady] = useState(false);
  const [isInWatchlist, setIsInWatchlist] = useState(false);

  useEffect(() => {
    if (!wish) {
      return;
    }
    if (!wish.isLoading) {
      setIsReady(true);
      setIsInWatchlist(!!wish.wishId);
    }
  }, [wish]);

  return [isReady, isInWatchlist];
};

export default useIsFilmInWatchList;
