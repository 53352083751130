import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';
import PromotionalBanner from '@app/components/promotional-banner/banner-containers/PromotionalBanner';
import TranslateHtml from '@app/components/TranslateHtml';
import { getFaqUrl } from '@app/services/helpscout';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Copy = styled.div`
  margin-right: 10px;
  color: ${props => props.theme.color.white};

  @media (min-width: ${props => props.theme.mq.desktop}) {
    margin: 0 12px 0 0;
  }
`;

const CDMExpiryWarningBanner = ({
  dueToBrowserOrWindowsVersions,
  cookiePrefix,
}: {
  dueToBrowserOrWindowsVersions: 'browser' | 'windows';
  cookiePrefix: string;
}) => {
  const { t, lang } = useTranslation('common');

  const i18nKey =
    dueToBrowserOrWindowsVersions === 'browser'
      ? 'old_browser_version_message'
      : 'windows_7_or_8_message';

  return (
    <GenericBannerContainer cookiePrefix={cookiePrefix}>
      {({ doBannerDismiss }) => (
        <PromotionalBanner
          onDismiss={doBannerDismiss}
          bgColor={themeColors.free}
          fontColor={themeColors.white}
        >
          <Container>
            <Copy>
              <TranslateHtml
                t={t}
                i18nKey={`common:common.banners.cdm_expiry.${i18nKey}`}
                i18nVars={{
                  link_text: (
                    <StyledLink
                      href={getFaqUrl('widevine-revocation', { lang })}
                    >
                      {t('common:common.banners.cdm_expiry.link_text')}
                    </StyledLink>
                  ),
                }}
              />
            </Copy>
          </Container>
        </PromotionalBanner>
      )}
    </GenericBannerContainer>
  );
};

const StyledLink = styled.a`
  color: ${props => props.theme.color.white};
  text-decoration: underline;
  &:visited {
    color: ${props => props.theme.color.white};
  }
  &:hover {
    color: ${props => props.theme.color.white};
  }
`;

export default CDMExpiryWarningBanner;
