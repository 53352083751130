import { SyntheticEvent } from 'react';
import styled from '@emotion/styled';

import { color as themeColors } from '@app/themes/mubi-theme';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import { CloseIcon } from '@app/components/icons/CommonIcons';

type CloseButtonProps = {
  handleClose: (e?: SyntheticEvent) => void;
  width?: string;
  color?: string;
  padding?: string;
};

const CloseButton = ({
  handleClose,
  width = '16px',
  color = themeColors.white,
  padding,
}: CloseButtonProps) => (
  <StyledButton onClick={handleClose} padding={padding}>
    <CloseIcon color={color} width={width} />
  </StyledButton>
);

const StyledButton = styled(UnstyledButton)`
  ${({ padding }) => (padding ? `padding: ${padding}` : '')}
`;

export default CloseButton;
