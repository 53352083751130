import { ReactNode, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '@emotion/styled';

type ContainerProps = {
  fadeInSeconds: number;
  fadeInDelaySeconds: number;
};
const Container = styled.div<ContainerProps>`
  transition: ${props => `opacity ${props.fadeInSeconds}s`};
  transition-delay: ${props => `${props.fadeInDelaySeconds}s`};
  opacity: 0;

  &.appear-enter {
    opacity: 0;
  }

  &.appear-enter-active {
    opacity: 1;
  }

  &.appear-enter-done {
    opacity: 1;
  }
`;

type FadeInProps = {
  children: ReactNode;
  isShowing: boolean;
  fadeInSeconds?: number;
  fadeInDelaySeconds?: number;
};

const FadeIn = ({
  isShowing,
  children,
  fadeInSeconds = 0.5,
  fadeInDelaySeconds = 0,
}: FadeInProps) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(isShowing);
  }, [isShowing]);

  return (
    <CSSTransition
      in={fadeIn}
      classNames="appear"
      timeout={fadeInSeconds * 1000}
    >
      <Container
        fadeInSeconds={fadeInSeconds}
        fadeInDelaySeconds={fadeInDelaySeconds}
      >
        {children}
      </Container>
    </CSSTransition>
  );
};

export default FadeIn;
